<template>
  <NuxtLink
    class="flex cursor-pointer items-center font-sans text-base font-bold uppercase lg:text-xl"
    :class="classObject"
    :to="link"
    :target="external ? '_blank' : '_self'"
  >
    {{ title }}
    <ArrowRight :class="[arrowClass ? arrowClass : 'ml-6']" />
  </NuxtLink>
</template>

<script setup>
import { computed } from 'vue';
import ArrowRight from '@/components/atoms/Icons/ArrowRight.vue';
// eslint-disable-next-line vue/no-setup-props-destructure
const props = defineProps({
  title: String,
  type: {
    type: String,
    default: 'white',
  },
  link: String,
  external: Boolean,
  arrowClass: String,
});
const classObject = computed(() => ({
  'text-blue-lighter drop-shadow-[0_3px_10px_#86ebfc] hover:text-blue-hover hover:filter-none':
    props.type === 'white',
  'text-blue hover:text-blue-dark drop-shadow-[0_1px_5px_rgba(0,155,181,0.5)] hover:filter-none':
    props.type === 'blue',
}));
</script>
